import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import reportWebVitals from '../../reportWebVitals'

interface SEOProps {
  title: string
}

export const SEO: React.FC<SEOProps> = (props) => {
  useEffect(() => {
    reportWebVitals()
  }, [])

  return (
    <Helmet title={props.title}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
      />

      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <script
        src="https://assets.calendly.com/assets/external/widget.js"
        type="text/javascript"
      ></script>

      <meta
        name="adopt-website-id"
        content="9ef9e8c6-ef60-4407-bdb7-44f4e8f94164"
      />
      <script
        src="//tag.goadopt.io/injector.js?website_code=9ef9e8c6-ef60-4407-bdb7-44f4e8f94164"
        className="adopt-injector"
      ></script>

      <script>{`
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2929696,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `}</script>
    </Helmet>
  )
}
